import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MetamaskLogin from './compnents/MetamaskLogin';
import EarnConnect from './compnents/EarnConnect';

const replaceBtn = async() => {
  let connectBtn = document.querySelector("#__next > main > div.css-1t1rhl4 > div > div:nth-child(3) > div.css-0") || document.querySelector("#menu-button-\\:r1\\:")  || document.querySelector("#menu-button-\\:rm\\:")
  if (!connectBtn) {
    await new Promise(r => setTimeout(r, 100));
    replaceBtn()
  }else{
    // remove event listener
    const connectArea = ReactDOM.createRoot(connectBtn);
    connectArea.render(
    <React.StrictMode>
      <MetamaskLogin />
    </React.StrictMode>
    );

    // add event listener for earn button
    const earnBtn = document.querySelector("#__next > main > div.css-1t1rhl4 > div > div:nth-child(2) > div:nth-child(3) > a")
    if (earnBtn) {
      earnBtn.addEventListener('click', replaceEarnBtn)
    }
    let earnBtn2 = document.querySelector("#__next > main > div.saas-card.css-tjsvo6 > div > div > a:nth-child(3)") // mobile
    if (earnBtn2) {
      earnBtn2.addEventListener('click', replaceEarnBtn)
    }

    let earnBtn3 = document.querySelector(".chakra-menu__menu-list .chakra-menu__menuitem:nth-child(1)")
    if (earnBtn3) {
      earnBtn3.addEventListener('click', replaceEarnBtn)
    }
  }
}

const replaceEarnBtn = async() => {
  console.log("replaceEarnBtn")
  let earnBtn = document.querySelector("div > div.css-1doh6tj > div")
  if (!earnBtn) {
    await new Promise(r => setTimeout(r, 100));
    replaceEarnBtn()
  }else{
    const earnArea = ReactDOM.createRoot(earnBtn);
    earnArea.render(
    <React.StrictMode>
      <EarnConnect />
    </React.StrictMode>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);


replaceBtn()
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
